@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700|Love+Ya+Like+A+Sister);

body{
    font-family: 'Montserrat', sans-serif;
    font-size:16px;
    color:#545454;
}

.wrap{
    @media(min-width: $screen-xs-max){
        margin-top: -70px;
    }
}

.sister{
    font-family: 'Love Ya Like A Sister', cursive;
}

h1, h2, h3, h4, h5{
   font-family: 'Love Ya Like A Sister', cursive;
   color:#8a522d;
}

.slantBottom{
    padding-top:65px;
}

.home{
   .slantBottom{
        padding-top:0px;
    } 
}

.infoBox{
    background: #fff;
    color: #666;
    text-align: left;
    padding: 15px;
    box-shadow: 0 0 18px 0px rgba(0,0,0,0.6);
    h1,h2,h3,h4,h5{
        color:#8a522c !important;
        margin-top:0;
    }
    a{
        color: #666666 !important;
    }
    ul{
        margin:0;
        padding:0;
    }
    li{
        list-style-image: url('../images/bullet.png');
        list-style-position: inside;
        margin: 0 0 10px 0
    }
}

.rounded{
    display: inline-block;
    border-radius: 100px;
    overflow: hidden;
    margin-bottom:-20px;
}

.navbar-toggle {
    background-color:transparent;
    z-index: 9999999; 
    border:#fff;
}

.nav-primary{
    @media(max-width: $screen-sm){
        background: #bb9982;
        z-index: 999999;
        position: relative !important;
    }
}

.icon-bar{
    background: #000;
}

.main{
    img{
        max-width: 100%;
    }
}

a{
    color:#471f04;
}

.content a{
    color: #e4993d;
}

.sub-menu{
    display:none;
    padding: 0;
    background: #F1C898;
    font-size: 11px !important;
    z-index: 999999;
    @media(min-width: $screen-sm){
        display:none;
        width: 200px;
        position: absolute;
        left: -32px;
    }
    .li{
        margin: 10px 0;
    }
}



.menu-item-has-children:hover{
        .sub-menu{
            //display:block;
            li{
                
            }
        }
    }


.metaStuff{
    font-size:14px;
    color:#471f04;
}

article{
    h1,h2,h3,h4,h5{
        a{color: #8a522d};
    }
    h2{
        font-size:40px;
    }
}

.readMore{
    margin:0 auto;
    display:block;
}

.entry-content{
        margin-top: 20px;
}

.laser{
    width:30px;
    height:5px;
    position:absolute;
    background:#ff1c1c;
    border-radius: 100px;
    box-shadow: 0px 0px 5px #fff;
}

.laser1{
    top: 251px;
    left: 178px;
}

.laser2{
    top: 251px;
    left: 209px;
}

.benBubble{
    position: relative;
}