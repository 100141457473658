footer.woodFloor{
    padding:0 !important;
    .slantTop {
        width:100%;
        margin-bottom:40px;
    }
    
    .askQuestion{
        position: absolute;
        right: 0;
        top: 0;
        margin-top: -90px;
    }
    
    .social{
        display:inline-block;
        margin:0 10px;
    }
    
}

.linksbot a{
    margin:15px;
    color:#432717;
}