.hentry{
    margin: 15px 0;
    box-shadow: 0 0 15px 0px rgba(0,0,0,0.1);
    padding: 15px;
}

.single-post{
    .hentry{
        margin: 14px 0 0 0;
    }
}

.comment-body{
    box-shadow: 0 0 15px 0px rgba(0,0,0,0.1);
    padding: 15px;
    margin: 15px 0;
}

iframe{
    max-width:100% !important;  
}

#main {
  padding-top: 30px !important;
  background: #eaeaea !important;
}
.avada-row {max-width:750px !important; 
  background: #fff !important;
  padding: 0px 15px !important;
  border-radius: 15px !important;
  box-shadow: 1px 1px 25px #aeaeae !important;}
.post-content h1 {
  line-height: 40px !important;
  font-family: 'Open Sans' !important;
  font-size: 36px !important;
      padding-top: 30px;
}
.post-content h2 {
  line-height: 36px !important;
  font-family: 'Open Sans' !important;
  font-size: 28px !important;
}
.post-content h3 {
  line-height: 32px !important;
  font-family: 'Open Sans' !important;
  font-size: 25px !important;
}
img.borderimgs {border: 2px solid grey !important;
  border-radius: 10px !important;}
#wrapper .header-social, .header-v4 #small-nav, .header-v5 #small-nav, .page-title-container {display:none !important;}
.header-v5 #header .logo {
  max-width: 250px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
