.nav-primary{
    
    position: absolute;
    z-index: 9;
    width: 100%;

    ul{
        text-align:center;
        font-size:20px;
        text-transform:uppercase;
        
        li{
            display:inline-block;
            a{
                color:#fff;
                text-shadow: 2px 2px 8px rgba(0,0,0,0.6); 
            }
            &.current-menu-item a{
                color:#472710;
                font-weight:bold;
            }
        }
    }
}

.nav>li>a:focus, .nav>li>a:hover{
    background: none;
}

.banner{
    text-align:center;
}

.page-id-717 {
    .woodFloorTop{
        padding:100px 0 0 0 !important;  
        @media(min-width: $screen-xs-max){
            padding:25px 0 0 0 !important;
        }
    }
    .mainLogo {
        width:300px;
    }
}

.woodFloor{
    background: url('../images/woodback.jpg');
    background-size:cover;
    padding:65px 0 0 0;
    position:relative;
    text-align: center;
    h1,h2,h3,h4,h5{
        color:#fff;
    }
    a{
        color:#fff;
    }
    
    .benProfile{
        position: absolute;
        right: 0;
        bottom: 0;
    }
    
    .benBubble{
        position: absolute;
        right: 91px;
        bottom: 302px;
        background: url('../images/ben-bubble.png');
        display: block;
        width:212px;
        height:241px;
        color:#fff;
        padding: 20px 10px;
    }
    
    .pageTitle{
        background-image: url(../images/page_title.png);
        padding: 15px;
        z-index: 100;
        top: 100%;
        font-size: 41px;
        line-height: 121px;
        overflow: hidden;
        position: absolute;
        width: 150px;
        height: 171px;
        color: #fff;
        background-size: contain;
        background-repeat: no-repeat;
        margin-bottom: 34px;
    }
}

.slantBottom{
    position:relative;
    width:100%;
}

.mainLogo{
    margin:15px auto -120px auto;
    //z-index: 100;
    position: relative;
    width:400px;
    @media(min-width: $screen-sm-max){
        margin: 25px auto -160px;
    }
}

.home .mainLogo{
     margin-bottom: 0px;
}

header{
    .social{
        display:inline-block;
        margin:0 5px;
        width: 30px;
        img{
            max-width:100%;
            margin-top: -12px;
        }
    }
}